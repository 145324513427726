/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Button, Typography, Container, Grid2 } from "@mui/material";
import QRCode from "qrcode.react";
import logo from "../assets/logo.png";
import { io } from "socket.io-client";
import { API_URL, SOCKET_URL } from "../config/constants";
import SomInicio from "../assets/Som tempo.mp3";
import secondSound from "../assets/10seconds.mp3";
import whistlSound from "../assets/apito.mp3";
import { Howl } from "howler"; // Importando Howler
import { useSearchParams } from "react-router-dom";

const RoundTimer = () => {
  const [roundTime, setRoundTime] = useState(300); // 5 minutes in seconds
  const [intervalTime, setIntervalTime] = useState(60); // 1 minute in seconds
  const [currentRound, setCurrentRound] = useState(1);
  const [totalRounds, setTotalRounds] = useState(3);
  const [remainingTime, setRemainingTime] = useState(300);
  const [isRunning, setIsRunning] = useState(false);
  const [isInterval, setIsInterval] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(new Date());

  const socketRef = useRef(null); // Reference to the Socket.IO client
  const audioStart = new Howl({ src: [SomInicio], preload: true });
  const countdownSound = new Howl({ src: [secondSound], preload: true });
  const whistleSound = new Howl({ src: [whistlSound], preload: true });
  const countdownPlayedRef = useRef(false); // Rastreamento para evitar tocar múltiplas vezes

  const formatTime = (seconds) => {
    const h = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const m = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const s = String(seconds % 60).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  const formatCurrentTime = (date) => {
    const h = String(date.getHours()).padStart(2, "0");
    const m = String(date.getMinutes()).padStart(2, "0");
    const s = String(date.getSeconds()).padStart(2, "0");
    return `${h}:${m}:${s}`;
  };

  // const handleSync = useCallback(() => {
  //   if (socketRef.current) {
  //     console.log("Sending sync event to server...", {
  //       time: remainingTime,
  //       isRunning,
  //       currentRound,
  //       isInterval,
  //       roundTime,
  //       intervalTime,
  //       totalRounds,
  //     });
  //     socketRef?.current?.emit("sync", {
  //       time: remainingTime,
  //       isRunning,
  //       currentRound,
  //       isInterval,
  //       roundTime,
  //       intervalTime,
  //       totalRounds,
  //     });
  //   }
  // }, [remainingTime, isRunning, currentRound, isInterval]);

  useEffect(() => {
    if (sessionId) {
      socketRef.current = io(SOCKET_URL, {
        query: { sessionId: sessionId },
      });

      socketRef.current.on("connect", () => {
        console.log("Socket.IO connection established with ID:", sessionId);
      });

      socketRef.current.on("message", (message) => {
        console.log("Received message from server:", message);
      });

      socketRef.current.on("start", (data) => {
        console.log("startTimer", data);
        setRemainingTime(Number(data.roundTime));
        setIsRunning(Boolean(data.isRunning));
        setIsInterval(Boolean(data.isInterval));
        setRoundTime(Number(data.roundTime));
        setIntervalTime(Number(data.intervalTime));
        setTotalRounds(Number(data.totalRounds));
        startTimer();
      });

      socketRef.current.on("stop", (data) => {
        console.log("stopTimer", data);
        stopTimer();
      });

      socketRef.current.on("reset", (data) => {
        console.log("resetTimer", data);
        resetTimer();
      });

      socketRef.current.on("sync", (data) => {
        console.log("Sync data received:", data);
        // Ensure all values are numbers
        setRemainingTime(Number(data.roundTime) || roundTime);
        setIsRunning(Boolean(data.isRunning));
        setCurrentRound(Number(data.currentRound) || currentRound);
        setIsInterval(Boolean(data.isInterval));
        setRoundTime(Number(data.roundTime));
        setIntervalTime(Number(data.intervalTime));
        setTotalRounds(Number(data.totalRounds));
      });

      socketRef?.current?.emit("sync_request", {}, (response) => {
        console.log("Initial sync data:", response);
        setRemainingTime(Number(response.roundTime) || roundTime);
        setIsRunning(Boolean(response.isRunning));
        setCurrentRound(Number(response.currentRound) || currentRound);
        setIsInterval(Boolean(response.isInterval));
        setRoundTime(Number(data.roundTime));
        setIntervalTime(Number(data.intervalTime));
        setTotalRounds(Number(data.totalRounds));
      });

      if (socketRef.current) {
        socketRef?.current?.emit("sync", {
          time: remainingTime,
          isRunning,
          currentRound,
          isInterval,
          roundTime,
          intervalTime,
          totalRounds,
        });
        console.log("Sync event emitted:", {
          time: remainingTime,
          isRunning,
          currentRound,
          isInterval,
          roundTime,
          intervalTime,
          totalRounds,
        });
      }

      socketRef.current.on("disconnect", () => {
        console.log("Socket.IO connection closed");
      });

      socketRef.current.on("error", (error) => {
        console.error("Socket.IO error:", error);
      });
    }

    return () => {
      if (socketRef.current && sessionId) {
        socketRef.current.disconnect();
      }
    };
  }, [sessionId]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const existingSessionId = urlParams.get("session_id");

    console.log("API_URL:", API_URL);

    if (existingSessionId) {
      console.log("Using existing session ID:", existingSessionId);
      setSessionId(existingSessionId);
    } else {
      console.log("Generating a new session ID...");
      fetch(`${API_URL}/generate_session`)
        .then((response) => response.json())
        .then((data) => {
          const newSessionId = data.session_id;
          console.log("Generated new session ID:", newSessionId);
          setSessionId(newSessionId);
          setSearchParams({ session_id: newSessionId });
        })
        .catch((error) => console.error("Error generating session:", error));
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect(); // Clean up on component unmount
      }
    };
  }, []);

  const isTransitioningRef = useRef(false); // Referência para controlar transições

  const handleRoundEnd = () => {
    if (isTransitioningRef.current) return; // Previne chamadas duplicadas
    isTransitioningRef.current = true; // Marca como em transição

    // Reseta o controle do som de contagem regressiva
    countdownPlayedRef.current = false;

    if (isInterval) {
      // Se estiver no intervalo
      if (currentRound < totalRounds) {
        playStartSoundAndStartNextRound(); // Inicia o próximo round com som
      } else {
        setIsRunning(false); // Todos os rounds concluídos
      }
    } else {
      // Não é intervalo, inicia o intervalo
      startInterval();
    }

    // Libera a transição após uma pausa para evitar conflitos
    setTimeout(() => {
      isTransitioningRef.current = false;
    }, 1000); // Ajuste do tempo conforme necessário
  };

  useEffect(() => {
    let timer = null;

    if (isRunning) {
      // Timer ou relógio sempre ativo
      timer = setInterval(() => {
        // Atualiza o horário atual
        // setCurrentTime(new Date());

        // Atualiza o timer principal se estiver rodando
        if (isRunning && remainingTime > 0) {
          setRemainingTime((prevTime) => {
            if (prevTime === 5) {
              if (isInterval) {
                playSound(whistleSound); // Toca o apito nos últimos 5 segundos do intervalo
              } else if (prevTime === 5 && !countdownPlayedRef.current) {
                playSound(countdownSound); // Toca o som de countdown nos últimos 5 segundos do round
                countdownPlayedRef.current = true;
              }
            }
            return prevTime - 1; // Reduz o tempo
          });
        }

        // Lida com o término do timer
        if (remainingTime === 0 && isRunning) {
          handleRoundEnd();
        }
      }, 1000);
    }

    return () => clearInterval(timer); // Limpa o intervalo ao desmontar ou alterar dependências
  }, [isRunning, remainingTime, isInterval, handleRoundEnd]);

  const playSound = (sound) => {
    if (sound.playing()) {
      sound.stop(); // Para o som se já estiver tocando
    }
    sound.play(); // Reproduz o som
  };

  const stopAllSounds = () => {
    audioStart.stop();
    countdownSound.stop();
    whistleSound.stop();
  };

  const playStartSoundAndStartNextRound = () => {
    stopAllSounds(); // Garante que nenhum outro som esteja tocando
    playSound(audioStart); // Toca o som no início do próximo round
    setTimeout(() => {
      startNextRound(); // Inicia o próximo round após o som
    }, 1000); // Ajuste do tempo conforme necessário
  };

  const startNextRound = () => {
    stopAllSounds(); // Pausa todos os sons
    playSound(audioStart); // Toca o som no início do próximo round
    countdownPlayedRef.current = false; // Reseta o som do countdown
    setIsInterval(false);
    setCurrentRound((prevRound) => prevRound + 1);
    setRemainingTime(roundTime);
    setIsRunning(true);
  };

  const startInterval = () => {
    countdownPlayedRef.current = false; // Reseta o som do countdown
    whistleSound.stop(); // Pausa o apito
    setIsInterval(true);
    setRemainingTime(intervalTime);
    setIsRunning(true);
  };

  const startTimer = () => {
    setIsRunning(true);
    playSound(audioStart);
  };

  const stopTimer = () => {
    stopAllSounds();
    setIsRunning(false);
  };

  const resetTimer = () => {
    setIsRunning(false);
    setCurrentRound(1);
    setIsInterval(false);
    setRemainingTime(300);
    setRoundTime(300);
    setIntervalTime(60);

    countdownPlayedRef.current = false; // Reseta o som do countdown
  };

  const handleRoundTimeChange = (event) => {
    const [hours, minutes, seconds] = event.target.value.split(":").map(Number);
    const totalSeconds =
      (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);

    socketRef?.current?.emit("sync", {
      time: remainingTime,
      isRunning,
      currentRound,
      isInterval,
      roundTime: totalSeconds,
      intervalTime,
      totalRounds,
    });
    setRoundTime(totalSeconds);
    setRemainingTime(totalSeconds);
  };

  const handleIntervalTimeChange = (event) => {
    const [hours, minutes, seconds] = event.target.value.split(":").map(Number);
    const totalSeconds =
      (hours || 0) * 3600 + (minutes || 0) * 60 + (seconds || 0);
    socketRef?.current?.emit("sync", {
      time: remainingTime,
      isRunning,
      currentRound,
      isInterval,
      roundTime,
      intervalTime: totalSeconds,
      totalRounds,
    });
    setIntervalTime(totalSeconds);
  };

  const handleTotalRoundsChange = (event) => {
    socketRef?.current?.emit("sync", {
      time: remainingTime,
      isRunning,
      currentRound,
      isInterval,
      roundTime,
      intervalTime,
      totalRounds: Number(event.target.value),
    });

    setTotalRounds(Number(event.target.value));
  };

  // useEffect(() => {
  //   const timer = setInterval(() => setDate(new Date()), 1000);
  //   return function cleanup() {
  //     clearInterval(timer);
  //   };
  // });

  function secondsToHMS(seconds) {
    // Ensure input is a number and positive
    seconds = Math.max(0, parseInt(seconds, 10));

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    // Format with leading zeros if needed
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(secs).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#121212",
        padding: 2,
      }}
    >
      <Grid2
        container
        spacing={2}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {/* Centralizar a Logo */}
        <Grid2
          container
          size={{ xs: 12 }}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            padding: "20px",
            marginRight: 2,
          }}
        >
          <img
            src={logo}
            alt="Company Logo"
            style={{
              width: "270px",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              // marginLeft: "420px",
              // marginRight: "auto",
            }}
          />

          <Grid2 item>
            <Typography variant="h4">{date.toLocaleTimeString()}</Typography>
          </Grid2>
        </Grid2>

        <Grid2
          container
          spacing={4}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Altera para coluna no celular
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid2
              container
              sx={{
                width: "200px",
                height: "130px",
                backgroundColor: "#1E1F22",
                borderRadius: "16px",
                border: "2px solid #6C63FF",
                display: "flex",
                marginRight: 2,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "16px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                Round (mm:ss)
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {secondsToHMS(roundTime)}
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid2
              container
              sx={{
                width: "200px",
                height: "130px",
                backgroundColor: "#1E1F22",
                borderRadius: "16px",
                border: "2px solid #6C63FF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "16px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                Intervalo (mm:ss)
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {secondsToHMS(intervalTime)}
              </Typography>
            </Grid2>
          </Grid2>

          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Grid2
              container
              sx={{
                width: "200px",
                height: "130px",
                backgroundColor: "#1E1F22",
                borderRadius: "16px",
                border: "2px solid #6C63FF",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "16px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                Qtd. de Rounds
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "20px",
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {totalRounds}
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2
          size={{ xs: 12, md: 2 }}
          sx={{
            backgroundColor: "#1e1e1e",
            borderRadius: "16px",
            padding: "5px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "6px",
            justifyContent: "center",
            width: { xs: "100%", sm: "100%", md: "730px" },
            mb: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              marginBottom: 2,
              marginTop: 2,
              padding: "5px",
              color: "#FFFFFF",
              fontSize: { xs: "40px", md: "20px" },
            }}
          >
            {isInterval ? "Intervalo" : `Round ${currentRound}`}
          </Typography>
          <Typography
            variant="h1"
            sx={{
              marginBottom: 2,
              color: "#FFFFFF",
              fontSize: { xs: "40px", md: "120px" },
            }}
          >
            {formatTime(remainingTime)}
          </Typography>
        </Grid2>

        <Grid2
          container
          spacing={4}
          justifyContent="center"
          sx={{
            mb: 2,
          }}
          size={{ xs: 12 }}
        >
          <Grid2
            size={{ xs: 12, md: 4 }}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={startTimer}
              sx={{
                m: 1,
                backgroundColor: "#7600ED",
                color: "#FFFF",
                height: "35px",
                width: "200px",
                borderRadius: "10px",
              }}
            >
              Começar
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              color="secondary"
              onClick={stopTimer}
              sx={{
                m: 1,
                backgroundColor: "#F63765",
                color: "#FFFF",
                height: "35px",
                width: "200px",
                borderRadius: "10px",
              }}
            >
              Parar
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              onClick={resetTimer}
              sx={{
                m: 1,
                backgroundColor: "transparent",
                color: "#FFFF",
                height: "35px",
                width: "200px",
                borderRadius: "10px",
              }}
            >
              Resetar
            </Button>
          </Grid2>
        </Grid2>
        <Grid2
          xs={12}
          md={6}
          sx={{
            textAlign: { xs: "center", md: "right" },
            display: "flex",
            justifyContent: { xs: "center", md: "center" },
          }}
        >
          {sessionId && (
            <QRCode
              value={sessionId}
              size={90}
              style={{
                margin: "0 10px",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            />
          )}
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default RoundTimer;
